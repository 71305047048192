var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/patient" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.patient")),
              },
            },
            [_vm._v("Patient")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/patient-consent" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.patientConsent")
                ),
              },
            },
            [_vm._v("Patient Consent")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/consent" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.consent")),
              },
            },
            [_vm._v("Consent")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/script" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.script")),
              },
            },
            [_vm._v("Script")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }