import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Patient = () => import('@/entities/patient/patient.vue');
const PatientUpdate = () => import('@/entities/patient/patient-update.vue');
const PatientDetails = () => import('@/entities/patient/patient-details.vue');

const PatientConsent = () => import('@/entities/patient-consent/patient-consent.vue');
const PatientConsentUpdate = () => import('@/entities/patient-consent/patient-consent-update.vue');
const PatientConsentDetails = () => import('@/entities/patient-consent/patient-consent-details.vue');

const Consent = () => import('@/entities/consent/consent.vue');
const ConsentUpdate = () => import('@/entities/consent/consent-update.vue');
const ConsentDetails = () => import('@/entities/consent/consent-details.vue');

const Script = () => import('@/entities/script/script.vue');
const ScriptUpdate = () => import('@/entities/script/script-update.vue');
const ScriptDetails = () => import('@/entities/script/script-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'patient',
      name: 'Patient',
      component: Patient,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient/new',
      name: 'PatientCreate',
      component: PatientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient/:patientId/edit',
      name: 'PatientEdit',
      component: PatientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient/:patientId/view',
      name: 'PatientView',
      component: PatientDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient-consent',
      name: 'PatientConsent',
      component: PatientConsent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient-consent/new',
      name: 'PatientConsentCreate',
      component: PatientConsentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient-consent/:patientConsentId/edit',
      name: 'PatientConsentEdit',
      component: PatientConsentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'patient-consent/:patientConsentId/view',
      name: 'PatientConsentView',
      component: PatientConsentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'consent',
      name: 'Consent',
      component: Consent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'consent/new',
      name: 'ConsentCreate',
      component: ConsentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'consent/:consentId/edit',
      name: 'ConsentEdit',
      component: ConsentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'consent/:consentId/view',
      name: 'ConsentView',
      component: ConsentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'script',
      name: 'Script',
      component: Script,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'script/new',
      name: 'ScriptCreate',
      component: ScriptUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'script/:scriptId/edit',
      name: 'ScriptEdit',
      component: ScriptUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'script/:scriptId/view',
      name: 'ScriptView',
      component: ScriptDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
